<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>
    <div v-for="(content,index) in contents" :key="index">
      <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
        <!--熊猫图标-->
        <el-col :span="2">
          <img class="icon" :src="iconImage" alt="">
        </el-col>
        <!--title-->
        <el-col :span="22">
          <p class="title">{{content.text}}</p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'text'">
        <el-col>
          <p class="demo-text" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryBlack'">
        <el-col class="secondaryTitle">
          <p class="secondaryBlack" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryTitle'">
        <el-col class="secondaryTitle">
          <p v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'noList'">
        <el-col>
          <li class="noList">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract'">
        <el-col class="retract">
          <li class="noList">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>
      <el-row v-else-if="content.type === 'noListBlack'">
        <el-col>
          <li class="noList black-text">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retractBlack'">
        <el-col class="retract">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2'">
        <el-col class="retract2">
          <li class="noList">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2Black'">
        <el-col class="retract2">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'textBlue'">
        <el-col>
          <p class="textBlue" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'img1'">
        <el-col class="platform">
          <img class="img" :src="oct20" alt="October 20">
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'img2'">
        <el-col class="platform">
          <img class="img" :src="oct21" alt="October 21">
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'img3'">
        <el-col class="platform">
          <img class="img" :src="oct22" alt="October 22">
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'img4'">
        <el-col class="platform">
          <img class="img" :src="oct23" alt="October 23">
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'img5'">
        <el-col class="platform">
          <img class="img" :src="oct24" alt="October 24">
        </el-col>
      </el-row>

    </div>
  </div>
</template>

<script>
export default {
  name: "program-at-a-glance",
  data() {
    return {
      iconImage: require("@/assets/content/bullet_point.png"),
      oct20: require("@/assets/program/oct_20.png"),
      oct21: require("@/assets/program/oct_21.png"),
      oct22: require("@/assets/program/oct_22.png"),
      oct23: require("@/assets/program/oct_23.png"),
      oct24: require("@/assets/program/oct_24.png"),
      mainTitle: 'ACM Multimedia 2021 Program at a Glance',
      contents:[
        {
          type:'text',
          text:'Last update: 10/13/2021',
        },
        {
          type:'primaryTitle',
          text:'Overview',
        },
        {
          type:'text',
          text:'This year ACM Multimedia will be held via a hybrid mode, i.e. jointly onsite in Chengdu, China and online. In particular, all the conference papers this year are expected to be presented more informatively to both onsite and online attendees.  The following points summarize the properties of this year conference:',
        },
        {
          type:'retract',
          text:'We adopt the traditional onsite conference form and divide the papers to oral and poster sessions with live streaming in each conference room, encouraging the onsite attendees to present their work physically and online attendees to provide live talks.',
        },
        {
          type:'retract',
          text:'We will also build a web portable platform (<a class="demo-link" href="https://whova.com/">Whova App</a>) to collect the pre-recoded videos and posters of all papers, which will facilitate all attendees to view these resources before and during the conference. The primary contact author of each paper in all tracks will receive an email that shows the instructions of uploading videos and posters to Whova. Moreover, the details instructions and requirements for preparing video/poster files can be found in <a class="demo-link" href="https://2021.acmmm.org/author-instruction">https://2021.acmmm.org/author-instruction</a>.'
        },
        {
          type:'retract',
          text:'Free poster printing service will be available during the conference to enable the attendees onsite to view the printed posters of the authors who cannot physically attend the conference, which is expected to further enrich their exchange on the research work. ',
        },
        // {
        //   type:'retract',
        //   text:'We will also build remote interaction with authors in Q&A sessions via Visual Discussion Rooms (Zoom meetings). QA sessions so designed would allow attendees from different time zones to participate fully at the conference online and onsite. We really expect both onsite and online attendees to take advantage of the onsite oral/poster session and Q&A sessions after studying as many as possible related papers to interact directly with the paper authors. The instructions about the Visual Discussion Rooms will be notified before the conference time.',
        // },
        
        {
          type:'primaryTitle',
          text:'Program at a Glance',
        },
        {
          type:'text',
          text:'The information for the Program at a Glance also can be found in the <b><a href="https://2021.acmmm.org/documents/ACMMM_2021_Draft_Agenda-Program_at_a_Glance.pdf">PDF</a></b>.',
          // text:'Please find more information in the <b><a href="https://2021.acmmm.org/documents/ACMMM_2021_Draft_Agenda-Program_at_a_Glance.pdf">PDF</a></b> for the Program at a Glance.',
        },
        {
          type:'secondaryTitle',
          text:'October 20, 2021',
        },
        {
          type:'text',
          text:'Tutorials / Workshops / Welcome Reception ',
        },
        {
          type:'img1',
          text:'October 20',
        },
        {
          type:'secondaryTitle',
          text:'October 21, 2021',
        },
        {
          type:'text',
          text:'Conference Opening / Keynote / Oral Session / Brave New Idea / Panel / Industrial Track / Poster',
        },
        {
          type:'img2',
          text:'October 21',
        },
        {
          type:'secondaryTitle',
          text:'October 22, 2021',
        },
        {
          type:'text',
          text:'Keynote / Oral Session / ACM SIGMM Awards/ Achievement Awards / Rising Star Talk / Outstanding PhD Thesis Presentation / Panel / Doctoral Symposium / Demo / Poster / Banquet Dinner',
        },
        {
          type:'img3',
          text:'October 22',
        },
        {
          type:'secondaryTitle',
          text:'October 23, 2021',
        },
        {
          type:'text',
          text:'Keynote / Oral Session / Open Source Competition / Multimedia Grand Challenge / Poster',
        },
        {
          type:'img4',
          text:'October 23',
        },
        {
          type:'secondaryTitle',
          text:'October 24, 2021',
        },
        {
          type:'text',
          text:'Tutorials / Workshops',
        },
        {
          type:'img5',
          text:'October 24',
        },

        {
          type:'text',
          text:'Detailed technical program will be available later.',
        },
        
      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2021 Program at a glance'
  }
}

</script>

<style scoped>
@import "../../css/responsive.css";

.img {
    width: 90%;
    /*width: 110rem;*/
    margin-top: 1rem;
    margin-left: 3rem;
    margin-bottom: 2rem;
    vertical-align: top;  
    text-align: center;
    /*display: inline-block;*/
}
</style>